import { useRef } from "react";

import Backdrop, { BackdropProps } from "@mui/material/Backdrop";

import usePreventZoom from "./hooks/usePreventZoom";

function NoZoomBackdrop(props: BackdropProps) {
  const ref = useRef<HTMLDivElement>(null);
  usePreventZoom(ref);

  return <Backdrop ref={ref} invisible {...props} />;
}

export default NoZoomBackdrop;
