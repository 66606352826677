import { createTheme, ThemeOptions } from "@mui/material/styles";
import NoZoomBackdrop from "@obr/common/NoZoomBackdrop";
import { OverlayEffect } from "./store/themeSlice";
import TransparentPaper from "./TransparentPaper";

export const baseOptions: ThemeOptions = {
  palette: {
    mode: "dark",
    primary: {
      main: "#bb99ff",
    },
    secondary: {
      main: "#ee99ff",
    },
    background: {
      paper: "#222639",
      default: "#1e2231",
    },
  },
  shape: {
    borderRadius: 16,
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          color: "secondary",
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 42,
          height: 26,
          padding: 0,
          margin: 8,
        },
        switchBase: {
          padding: 1,
          "&$checked, &$colorPrimary$checked, &$colorSecondary$checked": {
            transform: "translateX(16px)",
            color: "#fff",
            "& + $track": {
              opacity: 1,
              border: "none",
            },
          },
        },
        thumb: {
          width: 24,
          height: 24,
        },
        track: {
          borderRadius: 13,
          border: "1px solid #bdbdbd",
          backgroundColor: "#222639",
          opacity: 1,
          transition:
            "background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          margin: "4px 8px",
          borderRadius: "16px",
        },
        dense: {
          borderRadius: "12px",
        },
      },
    },
    MuiMenu: {
      defaultProps: {
        PaperProps: {
          variant: "transparent",
          elevation: 4,
        },
        BackdropComponent: NoZoomBackdrop,
      },
    },
    MuiSelect: {
      defaultProps: {
        MenuProps: {
          PaperProps: {
            variant: "transparent",
            elevation: 4,
          },
          BackdropComponent: NoZoomBackdrop,
        },
      },
    },
    MuiPopover: {
      defaultProps: {
        BackdropComponent: NoZoomBackdrop,
      },
    },
    MuiCard: {
      defaultProps: {
        variant: "transparent",
      },
    },
    MuiSnackbarContent: {
      styleOverrides: {
        root: {
          minWidth: "192px !important",
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          backgroundImage:
            "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))",
        },
      },
    },
    MuiImageListItem: {
      styleOverrides: {
        root: {
          borderRadius: "16px",
          overflow: "hidden",
          backgroundImage:
            "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))",
        },
      },
    },
    MuiImageListItemBar: {
      styleOverrides: {
        root: {
          background:
            "linear-gradient(to top, rgba(0,0,0,0.7) 0%, " +
            "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          "&:-webkit-autofill": {
            WebkitBoxShadow: "0 0 0 100px #222639 inset",
          },
          borderRadius: 16,
          "&::-webkit-search-cancel-button": {
            appearance: "none",
            display: "inline-block",
            width: "11px",
            height: "12px",
            marginLeft: "10px",
            background:
              "linear-gradient(45deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 43%, rgb(255, 255, 255) 45%, rgb(255, 255, 255) 55%, rgba(0, 0, 0, 0) 57%, rgba(0, 0, 0, 0) 100%), linear-gradient(135deg, transparent 0%, transparent 43%, rgb(255, 255, 255) 45%, rgb(255, 255, 255) 55%, transparent 57%, transparent 100%)",
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        hasClearIcon: {
          "input::-webkit-search-cancel-button": {
            background: "none",
          },
        },
      },
      defaultProps: {
        PaperComponent: TransparentPaper,
      },
    },
  },
};

export const getDarkThemeWithOverlayEffect = (overlayEffect: OverlayEffect) => {
  const defaultTheme = createTheme(baseOptions);

  const getBackgroundColor = (elevation: number) => {
    let alpha = 1;
    if (overlayEffect === "TRANSPARENT") {
      if (elevation > 1) {
        alpha = 0.95;
      } else {
        alpha = 0.9;
      }
    } else {
      if (elevation > 1) {
        alpha = 0.75;
      } else {
        alpha = 0.6;
      }
    }

    return `rgba(30, 34, 49, ${alpha})`;
  };

  const getBackdropFilter = (elevation: number) => {
    if (overlayEffect === "TRANSPARENT") {
      return "none";
    }
    if (elevation > 4) {
      return "blur(40px)";
    } else if (elevation > 3) {
      return "blur(30px)";
    } else if (elevation > 2) {
      return "blur(20px)";
    }
    return "blur(10px)";
  };

  const getBoxShadow = (elevation: number) => {
    return defaultTheme.shadows[elevation];
  };

  const getBackgroundImage = (elevation: number) => {
    if (elevation === 0) {
      return "none";
    }
    const opacity = (0.04 + 0.01 * elevation).toFixed(2);
    return `linear-gradient(rgba(255, 255, 255, ${opacity}), rgba(255, 255, 255, ${opacity}))`;
  };

  const paperOptions: ThemeOptions = {
    components: {
      MuiPaper: {
        variants: Array.from(Array(24).keys()).map((elevation) => ({
          props: { variant: "transparent", elevation },
          style: {
            backgroundColor: getBackgroundColor(elevation),
            boxShadow: getBoxShadow(elevation),
            backdropFilter: getBackdropFilter(elevation),
            backgroundImage: getBackgroundImage(elevation),
          },
        })),
      },
    },
  };

  return createTheme({
    ...baseOptions,
    components: { ...baseOptions.components, ...paperOptions.components },
  });
};

export const darkTheme = createTheme(baseOptions);
