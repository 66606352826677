import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { useWhatsNewModalStore, useWhatsNewStore } from "./store";

import WhatsNewIcon from "@mui/icons-material/AutoAwesomeRounded";

function WhatsNewButton() {
  const openWhatsNew = useWhatsNewModalStore((state) => state.openWhatsNew);
  const lastSeenAppVersion = useWhatsNewStore(
    (state) => state.lastSeenAppVersion
  );

  const showBadge = lastSeenAppVersion !== APP_VERSION;

  return (
    <Badge
      invisible={!showBadge}
      color="primary"
      variant="dot"
      overlap="circular"
    >
      <span>
        <Tooltip title="What's New">
          <IconButton onClick={openWhatsNew}>
            <WhatsNewIcon />
          </IconButton>
        </Tooltip>
      </span>
    </Badge>
  );
}

export default WhatsNewButton;
