import { Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Stack from "@mui/material/Stack";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import NavBar from "@obr/common/NavBar";
import AuthStatus from "@obr/routes/AuthStatus";
import { RequireAuth } from "@obr/features/auth/RequireAuth";
import AuthModal from "@obr/features/auth/AuthModal";
import Footer from "@obr/common/Footer";
import MaintenanceNotification from "@obr/common/MaintenanceNotification";
import WhatsNewModal from "@obr/features/whatsNew/WhatsNewModal";

const Home = lazy(() => import("@obr/routes/Home"));
const About = lazy(() => import("@obr/routes/About"));
const WaitingRoom = lazy(() => import("@obr/routes/WaitingRoom"));
const SignIn = lazy(() => import("@obr/routes/SignIn"));
const SignUp = lazy(() => import("@obr/routes/SignUp"));
const ResetPassword = lazy(() => import("@obr/routes/ResetPassword"));
const RecoverPassword = lazy(() => import("@obr/routes/RecoverPassword"));
const Profile = lazy(() => import("@obr/routes/Profile"));
const Pricing = lazy(() => import("@obr/routes/Pricing"));
const Privacy = lazy(() => import("@obr/routes/Privacy"));
const Terms = lazy(() => import("@obr/routes/Terms"));
const EmbedRoom = lazy(() => import("@obr/routes/EmbedRoom"));
const EmbedAccess = lazy(() => import("@obr/routes/EmbedAccess"));

function App() {
  return (
    <Stack sx={{ height: "100%" }}>
      <Suspense
        fallback={
          <Backdrop open unmountOnExit>
            <CircularProgress />
          </Backdrop>
        }
      >
        <Router>
          <NavBar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/room/:roomId/:roomName" element={<WaitingRoom />} />
            <Route path="/about" element={<About />} />
            <Route path="/sign-up" element={<SignUp />}></Route>
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/recover-password" element={<RecoverPassword />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route
              path="/embed/room/:roomId/:roomName"
              element={<EmbedRoom />}
            />
            <Route path="/embed/access" element={<EmbedAccess />} />
            <Route element={<AuthStatus />}>
              <Route path="/sign-in" element={<SignIn />}></Route>
              <Route
                path="/profile"
                element={
                  <RequireAuth>
                    <Profile />
                  </RequireAuth>
                }
              />
            </Route>
          </Routes>
          <AuthModal />
          <MaintenanceNotification />
          <WhatsNewModal />
          <Footer />
        </Router>
      </Suspense>
    </Stack>
  );
}

export default App;
