import { StateCreator } from "zustand";

export interface ShortcutsSlice {
  /** Keep a stack of shortcut scopes to allow changing scopes when modals open */
  scopeStack: string[];
  addScope: (scope: string) => string;
  removeScope: (scope: string) => string;
}

export const shortcutsSlice: StateCreator<ShortcutsSlice> = (set, get) => ({
  scopeStack: [],
  addScope: (scope) => {
    set((state) => {
      state.scopeStack.push(scope);
    });
    const stack = get().scopeStack;
    return stack[stack.length - 1] || "all";
  },
  removeScope: (scope) => {
    set((state) => {
      const index = state.scopeStack.lastIndexOf(scope);
      if (index > -1) {
        state.scopeStack.splice(index, 1);
      }
    });
    const stack = get().scopeStack;
    return stack[stack.length - 1] || "all";
  },
});
