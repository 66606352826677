import Paper, { PaperProps } from "@mui/material/Paper";

const ImageContainer: React.VFC<PaperProps> = ({ sx, ...props }) => {
  return (
    <Paper
      sx={{
        width: "100%",
        borderRadius: 1,
        overflow: "hidden",
        backgroundImage: "none",
        display: "flex",
        m: 2,
        ...sx,
      }}
      elevation={5}
      {...props}
    />
  );
};

export default ImageContainer;
